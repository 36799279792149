import { ReportType } from "@convin/type/AllReports";
import {
    AnalyticsParameterDetailGraphResult,
    AuditDashboardFiltersPayload,
    AuditDashboardGraphData,
    AuditLineGraph,
    AuditorAnalyticsData,
    AuditorAnalyticsGraphData,
    AuditorDashboardData,
    AuditorDashboardGraphFields,
    AuditorListColumnKeys,
    CollectionCategoryType,
    CollectionLineGraph,
    CollectionPieAnalyticsResult,
    CompositionBarMetric,
    CompositionLineMetric,
    CsatCategoryType,
    CsatLineGraph,
    CsatPieAnalyticsResult,
    DashboardReportTypes,
    GPTQuestion,
    LeadCompostionResult,
    LeadLineGraph,
    LeadPieAnalyticsResult,
    LeadStageType,
    LineGraphMetric,
    OverallAccuracyAnalytics,
    OverallCollectionPieAnalytics,
    OverallCsatPieAnalytics,
    OverallLeadPieAnalytics,
    OverallLineAnalytics,
    OverallPieAnalytics,
    ParameterStats,
    ShareAnalyticsPayloadType,
    ShareAnalyticsResultType,
    SingleBarMetric,
    SingleParameterAccuracyStats,
    StatisticsData,
    StatisticsGraphData,
} from "@convin/type/Analytics";
import { FiltersPayload } from "@convin/type/CustomDashboard";
import { BulkUploadData, DownloadReport, Report } from "@convin/type/Report";
import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType, SortParams } from "@convin/type/Common";
import { ConversationSearchPayload } from "@convin/type/Conversation";
import { GlobalFiltersStateType } from "@convin/contexts/GlobalFiltersStateContextProvider";
import {
    isDefined,
    updateDownloadQue,
} from "@convin/utils/helper/common.helper";

const StatesticsPaylod = {
    fields: ["duration", "volume"],
    group_by_owner: false,
};

export const analyticsDashboardApiSlice = createApi({
    reducerPath: "analyticsDashboardApiSlice",
    baseQuery: axiosBaseQuery(),
    keepUnusedDataFor: 60,
    endpoints: (builder) => ({
        getOverallAnalytics: builder.query<
            OverallPieAnalytics,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/overall/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getOverallAnalyticsLineGraph: builder.query<
            OverallLineAnalytics<AuditLineGraph>[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/overall/graph/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getTeamAverageAnalytics: builder.query<
            SingleBarMetric[] | LineGraphMetric[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/team/average/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getCallCompositionAnalytics: builder.query<
            CompositionBarMetric[] | CompositionLineMetric<AuditLineGraph>[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/team/composition/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getRepCompositionAnalytics: builder.query<
            CompositionBarMetric[] | CompositionLineMetric<AuditLineGraph>[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/team/comparison/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getRepAverageAnalytics: builder.query<
            Array<SingleBarMetric> | Array<LineGraphMetric>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/rep/average/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getParametersAverageAnalytics: builder.query<
            Array<SingleBarMetric> | Array<LineGraphMetric>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/parameter/average/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        /*----- Violation Dashboard APIs -----*/
        getViolationsAverageAnalytics: builder.query<
            Array<SingleBarMetric> | Array<LineGraphMetric>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/violations/average/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getViolationsDetailsBasedOnIdAnalytics: builder.query<
            Array<SingleBarMetric> | Array<LineGraphMetric>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/violations/detail/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getViolationsComposition: builder.query<
            Array<Omit<LineGraphMetric, "overall_average">>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/violations/composition/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        /*------------------------*/

        getStatistics: builder.query<
            StatisticsData,
            {
                filters: ConversationSearchPayload["filters"];
                meetingType: GlobalFiltersStateType["meetingType"];
            }
        >({
            query: ({ filters, meetingType }) => {
                return {
                    url: "/stats/overall/stats/",
                    method: "POST",
                    body: {
                        ...StatesticsPaylod,
                        filters: filters,
                        group_by_time: null,
                        aggregations:
                            meetingType === "call"
                                ? ["avg", "sum", "max", "in_ideal_range"]
                                : ["max"],
                    },
                    transformResponse: (response: string) => {
                        const res = JSON.parse(response) as StatisticsData;
                        return {
                            ...res,
                            overall_avg:
                                +(res.overall_avg / 60)?.toFixed(2) || 0,
                            total_duration:
                                +(res.total_duration / 3600)?.toFixed(2) || 0,
                            total_volume: +res.total_volume?.toFixed(2) || 0,
                        };
                    },
                };
            },
        }),
        getStatisticsGraph: builder.query<
            StatisticsGraphData,
            {
                filters: ConversationSearchPayload["filters"];
                meetingType: GlobalFiltersStateType["meetingType"];
            }
        >({
            query: ({ meetingType, filters }) => {
                return {
                    url: "/stats/overall/graph/",
                    method: "POST",
                    body: {
                        ...StatesticsPaylod,
                        filters,
                        group_by_time: "day",
                        aggregations:
                            meetingType === "call"
                                ? ["avg", "sum", "max", "in_ideal_range"]
                                : ["max"],
                    },
                    transformResponse: (response: string) => {
                        return (
                            JSON.parse(response) as StatisticsGraphData
                        )?.map((e) => ({
                            ...e,
                            average: +(e.average / 60)?.toFixed(2) || 0,
                            duration: +(e.duration / 3600)?.toFixed(2) || 0,
                            volume: +e.volume?.toFixed(2) || 0,
                        }));
                    },
                };
            },
        }),
        getAuditorAnalytics: builder.query<
            AuditorAnalyticsData,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/audit_report/overall/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getParameterDetailedAnalysis: builder.query<
            (ParameterStats & { id: number }) | Record<never, never>,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/audit/template/overall_score/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getSingleParameterPerformance: builder.query<
            AnalyticsParameterDetailGraphResult[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/audit/question/detail_graph/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getAuditorAnalyticsGraph: builder.query<
            AuditorAnalyticsGraphData,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/audit_report/graph/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        /*----- Lead Dashboard APIs -----*/
        getLeadOverallPieAnalytics: builder.query<
            OverallLeadPieAnalytics,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/lead_score/dashboard/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getLeadOverallGraphAnalytics: builder.query<
            OverallLineAnalytics<LeadLineGraph>[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/lead_score/graph/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getLeadAverageTeamScore: builder.query<
            LineGraphMetric[] | SingleBarMetric[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/lead_score/average/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getAverageLeadComposition: builder.query<
            LeadCompostionResult[] | CompositionLineMetric<LeadLineGraph>[],
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/lead_score/compostion/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getLeadScoreIdData: builder.query<
            LineGraphMetric[] | SingleBarMetric[],
            Partial<FiltersPayload> & { stage: LeadStageType }
        >({
            query: ({ stage, ...payload }) => {
                return {
                    url: `/analytics/lead_score/detail/?stage=${stage}`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getLeadDistribution: builder.query<
            LeadPieAnalyticsResult,
            Partial<FiltersPayload>
        >({
            query: (payload) => {
                return {
                    url: "/analytics/lead_score/distribution/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        /*---------------------*/

        getAllReports: builder.query<Array<Report>, void>({
            query: () => "/analytics/reports/list/",
        }),
        getReportByType: builder.query<
            ReportType,
            Partial<FiltersPayload> & { type: string; classification?: string }
        >({
            query: (payload) => {
                return {
                    url: "/analytics/reports/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getDashboardReportList: builder.query<Report[], DashboardReportTypes>({
            query: (payload) => {
                return {
                    url: "/analytics/dashboard/reports/list/",
                    method: "POST",
                    body: { dashboard: payload },
                };
            },
        }),
        getAiGptQuestions: builder.query<GPTQuestion[], void>({
            query: () => "/gpt/question/list/",
        }),

        //AUDIT DASHBOARD -- START

        getOverallAuditsData: builder.query<
            AuditorAnalyticsData,
            AuditDashboardFiltersPayload
        >({
            query: (payload) => {
                return {
                    url: "/stats/audit/overall/",
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getAuditorListData: builder.query<
            PaginationType<AuditorDashboardData>,
            AuditDashboardFiltersPayload & {
                params?: SortParams<AuditorListColumnKeys>;
            }
        >({
            query: ({ params, ...payload }) => {
                return {
                    url: "/stats/auditor/list/",
                    method: "POST",
                    body: payload,
                    params,
                };
            },
        }),
        getAuditorGraphData: builder.query<
            AuditDashboardGraphData,
            {
                id: number;
                payload: AuditDashboardFiltersPayload & {
                    fields: Array<AuditorDashboardGraphFields>;
                };
            }
        >({
            query: ({ id, payload }) => {
                return {
                    url: `/stats/auditor/${id}/graph/`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        downloadAuditorExcelReport: builder.mutation<
            DownloadReport,
            AuditDashboardFiltersPayload
        >({
            query: (payload) => ({
                url: "/stats/auditor/report/download/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        dispatch,
                        data,
                    });
                } catch (err) {}
            },
        }),

        downloadAuditorRawReport: builder.mutation<
            DownloadReport,
            AuditDashboardFiltersPayload & { auditor_id?: number }
        >({
            query: (payload) => ({
                url: "/stats/auditor/raw/download/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        dispatch,
                        data,
                    });
                } catch (err) {}
            },
        }),
        downloadQmsAuditorRawReport: builder.mutation<
            DownloadReport,
            Partial<AuditDashboardFiltersPayload> & {
                type: string;
                is_audit_dashboard_report: string;
            }
        >({
            query: (payload) => ({
                url: "/analytics/download/reports/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        shareAuditReport: builder.mutation<
            void,
            AuditDashboardFiltersPayload & {
                emails: string[];
                comment: string;
                filename: string;
            }
        >({
            query: (payload) => ({
                url: "/stats/auditor/report/share/",
                method: "POST",
                body: payload,
            }),
        }),

        //AUDIT DASHBOARD -- END

        downloadReportExcelSheet: builder.mutation<
            DownloadReport,
            Partial<FiltersPayload> & { type: string }
        >({
            query: (payload) => ({
                url: "/analytics/download/reports/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        data,
                        dispatch,
                    });
                } catch (err) {}
            },
        }),
        sendReportMail: builder.mutation<
            unknown,
            Partial<FiltersPayload & { classification: string }> & {
                type: string;
            }
        >({
            query: (payload) => ({
                url: "/analytics/email/reports/",
                method: "POST",
                body: payload,
            }),
        }),

        /*----- Csat Collection Dashboard APIs -----*/

        getCsatCollectionOverallPieAnalytics: builder.query<
            OverallCsatPieAnalytics | OverallCollectionPieAnalytics,
            Partial<FiltersPayload> & { isCsat: boolean }
        >({
            query: ({ isCsat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/dashboard/?is_csat=${
                        isCsat ? 1 : 0
                    }`,
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getCsatCollectionOverallGraphAnalytics: builder.query<
            OverallLineAnalytics<CsatLineGraph | CollectionLineGraph>[],
            Partial<FiltersPayload> & { isCsat: boolean }
        >({
            query: ({ isCsat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/graph/?is_csat=${
                        isCsat ? 1 : 0
                    }`,
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getCsatCollectionAverageTeamScore: builder.query<
            LineGraphMetric[] | SingleBarMetric[],
            Partial<FiltersPayload> & { is_csat: boolean }
        >({
            query: ({ is_csat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/average/?is_csat=${
                        is_csat ? 1 : 0
                    }`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getAverageCsatCollectionComposition: builder.query<
            | LeadCompostionResult[]
            | CompositionLineMetric<CsatLineGraph | CollectionLineGraph>[],
            Partial<FiltersPayload> & { is_csat: boolean }
        >({
            query: ({ is_csat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/composition/?is_csat=${
                        is_csat ? 1 : 0
                    }`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getCsatCollectionScoreIdData: builder.query<
            LineGraphMetric[] | SingleBarMetric[],
            Partial<FiltersPayload> & {
                category: CsatCategoryType | CollectionCategoryType;
                is_csat: boolean;
            }
        >({
            query: ({ category, is_csat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/detail/?is_csat=${
                        is_csat ? 1 : 0
                    }&category=${category}`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        getCsatCollectionDistribution: builder.query<
            CsatPieAnalyticsResult | CollectionPieAnalyticsResult,
            Partial<FiltersPayload> & { is_csat: boolean }
        >({
            query: ({ is_csat, ...payload }) => {
                return {
                    url: `/analytics/csat_collection/distribution/?is_csat=${
                        is_csat ? 1 : 0
                    }`,
                    method: "POST",
                    body: payload,
                };
            },
        }),
        /*---------------------*/

        shareAnalytics: builder.mutation<
            ShareAnalyticsResultType,
            ShareAnalyticsPayloadType
        >({
            query: (payload) => {
                return {
                    url: "/share/meetings/",
                    method: "POST",
                    body: payload,
                };
            },
        }),

        getReportsDownloadQue: builder.query<
            PaginationType<DownloadReport>,
            {
                next?: string;
                type?: "internal";
            }
        >({
            query: ({ next, type }) => {
                return {
                    url: next || "/analytics/download/reports/",
                    ...(type && {
                        params: { type },
                    }),
                };
            },
        }),
        getBulkUploadList: builder.query<
            PaginationType<BulkUploadData<"MANUAL_QMS" | "USER_LIST">>,
            {
                next?: string;
            }
        >({
            query: ({ next }) => next || "/feedback/bulk_upload/",
        }),
        deleteBulkUpload: builder.mutation<void, number>({
            query: (id) => ({
                url: `/feedback/bulk_upload/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { queryFulfilled, dispatch }) {
                const deleteOperation = dispatch(
                    analyticsDashboardApiSlice.util.updateQueryData(
                        "getBulkUploadList",
                        {},
                        (draft) => ({
                            ...draft,
                            count: draft.count - 1,
                            results: draft.results.filter((e) => e.id !== id),
                        })
                    )
                );
                try {
                    await queryFulfilled;
                } catch (err) {
                    deleteOperation.undo();
                }
            },
        }),
        deleteBulkUploadList: builder.mutation<void, void>({
            query: () => ({
                url: "/feedback/bulk_upload/",
                method: "DELETE",
            }),
        }),
        handleDownloadReport: builder.mutation<
            DownloadReport & { report_url: string },
            number
        >({
            query: (id) => `/analytics/download/report/metadata/${id}/`,
            async onQueryStarted(id, { queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if (isDefined(data.report_url)) {
                        const link = document.createElement("a");
                        link.href = data.report_url;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                } catch (err) {}
            },
        }),
        deleteDownloadReport: builder.mutation<void, number>({
            query: (id) => ({
                url: `/analytics/download/report/metadata/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(id, { queryFulfilled, dispatch }) {
                const deleteOperation = dispatch(
                    analyticsDashboardApiSlice.util.updateQueryData(
                        "getReportsDownloadQue",
                        {},
                        (draft) => ({
                            ...draft,
                            count: draft.count - 1,
                            results: draft.results.filter((e) => e.id !== id),
                        })
                    )
                );
                try {
                    await queryFulfilled;
                } catch (err) {
                    deleteOperation.undo();
                }
            },
        }),
        retryDownloadReport: builder.mutation<DownloadReport, number>({
            query: (id) => `/analytics/download/report/retry/${id}/`,
            async onQueryStarted(id, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        analyticsDashboardApiSlice.util.updateQueryData(
                            "getReportsDownloadQue",
                            {},
                            (draft) => ({
                                ...draft,
                                results: draft.results.map((e) =>
                                    data.id === e.id ? data : e
                                ),
                            })
                        )
                    );
                } catch (err) {}
            },
        }),
        deleteAllReportNotification: builder.mutation<void, void>({
            query: () => ({
                url: "/analytics/download/reports/",
                method: "DELETE",
            }),
        }),

        //Accuracy Dashbaord API's
        getAiAccuracyStats: builder.query<
            OverallAccuracyAnalytics,
            Partial<FiltersPayload>
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/overall/",
                method: "POST",
                body: payload,
            }),
        }),

        getAccuracyChartData: builder.query<
            Array<{
                epoch: number;
                accuracy: number;
                cumulative_average: number;
            }>,
            Partial<FiltersPayload>
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/graph/",
                method: "POST",
                body: payload,
            }),
        }),
        getTemplatesAccuracyStats: builder.query<
            Array<{
                template_id: number;
                accuracy: number;
                ai_score: number;
                manual_score: number;
                name: string;
            }>,
            Partial<FiltersPayload>
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/templates/",
                method: "POST",
                body: payload,
            }),
        }),
        getAuditorsAccuracyStats: builder.query<
            Array<{
                id: number;
                email: string;
                first_name: string;
                accuracy: number;
                ai_score: number;
                manual_score: number;
                total: number;
            }>,
            Partial<FiltersPayload>
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/auditor/",
                method: "POST",
                body: payload,
            }),
        }),
        getParametersAccuracyStats: builder.query<
            Array<{
                change_accuracy: number;
                curr_accuracy: number;
                question_id: number;
                question_text: string;
            }>,
            Partial<FiltersPayload> & { category_id: number | null }
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/parameter/ ",
                method: "POST",
                body: payload,
            }),
        }),
        getSingleParameterAccuracyStats: builder.query<
            SingleParameterAccuracyStats,
            Partial<FiltersPayload> & { question_id: number }
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/parameter/detail/",
                method: "POST",
                body: payload,
            }),
        }),
        downloadAccuracyDashboardReport: builder.mutation<
            DownloadReport,
            Partial<FiltersPayload> & {
                category_id?: number | null;
                question_id?: number | null;
                ai_response_id?: number;
                manual_response_id?: number;
                type:
                    | "audit_template_report"
                    | "templates_accuracy_dashboard_report"
                    | "recall_accuracy_dashboard_report"
                    | "ai_vs_manual_accuracy_dashboard_report"
                    | "auditor_accuracy_dashboard_report"
                    | "accuracy_dashboard_dump_report";
            }
        >({
            query: (payload) => ({
                url: "/analytics/accuracy_dashboard/overall/report/",
                method: "POST",
                body: payload,
            }),
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                try {
                    const { data } = await queryFulfilled;
                    updateDownloadQue({
                        dispatch,
                        data,
                    });
                } catch (err) {}
            },
        }),
    }),
});

export const {
    useGetOverallAnalyticsQuery,
    useGetOverallAnalyticsLineGraphQuery,
    useGetStatisticsGraphQuery,
    useGetStatisticsQuery,
    useGetTeamAverageAnalyticsQuery,
    useGetCallCompositionAnalyticsQuery,
    useGetRepCompositionAnalyticsQuery,
    useGetRepAverageAnalyticsQuery,
    useGetParametersAverageAnalyticsQuery,
    useGetAuditorAnalyticsQuery,
    useGetAuditorAnalyticsGraphQuery,
    useGetAllReportsQuery,
    useGetReportByTypeQuery,
    useGetViolationsAverageAnalyticsQuery,
    useGetAiGptQuestionsQuery,
    useGetDashboardReportListQuery,
    useGetParameterDetailedAnalysisQuery,
    useLazyGetParameterDetailedAnalysisQuery,
    useGetViolationsDetailsBasedOnIdAnalyticsQuery,
    useDownloadReportExcelSheetMutation,
    useLazyGetSingleParameterPerformanceQuery,
    useGetLeadAverageTeamScoreQuery,
    useGetAverageLeadCompositionQuery,
    useGetLeadScoreIdDataQuery,
    useGetLeadDistributionQuery,
    useGetOverallAuditsDataQuery,
    useGetAuditorListDataQuery,
    useLazyGetAuditorListDataQuery,
    useGetAuditorGraphDataQuery,
    useDownloadAuditorExcelReportMutation,
    useDownloadAuditorRawReportMutation,
    useDownloadQmsAuditorRawReportMutation,
    useShareAuditReportMutation,
    useGetCsatCollectionAverageTeamScoreQuery,
    useGetAverageCsatCollectionCompositionQuery,
    useGetCsatCollectionScoreIdDataQuery,
    useGetCsatCollectionDistributionQuery,
    useShareAnalyticsMutation,
    useGetLeadOverallGraphAnalyticsQuery,
    useGetLeadOverallPieAnalyticsQuery,
    useGetCsatCollectionOverallGraphAnalyticsQuery,
    useGetCsatCollectionOverallPieAnalyticsQuery,
    useGetViolationsCompositionQuery,
    useGetReportsDownloadQueQuery,
    useLazyGetReportsDownloadQueQuery,
    useGetBulkUploadListQuery,
    useLazyGetBulkUploadListQuery,
    useDeleteDownloadReportMutation,
    useRetryDownloadReportMutation,
    useDeleteAllReportNotificationMutation,
    useHandleDownloadReportMutation,
    useSendReportMailMutation,
    useGetAiAccuracyStatsQuery,
    useGetAccuracyChartDataQuery,
    useGetTemplatesAccuracyStatsQuery,
    useGetAuditorsAccuracyStatsQuery,
    useGetParametersAccuracyStatsQuery,
    useGetSingleParameterAccuracyStatsQuery,
    useDownloadAccuracyDashboardReportMutation,
    useDeleteBulkUploadMutation,
    useDeleteBulkUploadListMutation,
} = analyticsDashboardApiSlice;
