// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { TextField, UseAutocompleteProps, SxProps, Theme } from "@mui/material";
import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";

import VirtualListOptionsForSelect from "../select/VirtualListOptionsForSelect";
import { isDefined } from "@convin/utils/helper/common.helper";
import { Fragment, useCallback } from "react";
// ----------------------------------------------------------------------

export interface CustomAutoCompleteProps<
    T extends { id: number | string | null; label: string }
> {
    autocompleteProps: Omit<
        UseAutocompleteProps<
            T["id"],
            false,
            boolean | undefined,
            boolean | undefined
        >,
        "options"
    > &
        Partial<
            Omit<
                AutocompleteProps<
                    T["id"],
                    false,
                    boolean | undefined,
                    boolean | undefined
                >,
                "options"
            >
        > & {
            options: T[];
        };
    label: string;
    loading?: boolean;
    className?: string;
    limitTags?: number;
    sx?: SxProps<Theme>;
    error?: boolean;
    helperText?: string;
    placeholder?: string;
    required?: boolean;
}

export default function RHFSelectCustom<
    T extends { id: number | string; label: string }
>(props: CustomAutoCompleteProps<T> & { name: string }) {
    const { control, setValue } = useFormContext();
    const {
        name,
        label,
        error = false,
        helperText = "",
        placeholder = "Search",
        autocompleteProps: { options, ...rest },
        required,
        ...other
    } = props;

    const getLabel = useCallback(
        (id: T["id"]) => options?.find((e) => e.id === id)?.label || "",
        [options]
    );

    const handleOptionChange: CustomAutoCompleteProps<T>["autocompleteProps"]["onChange"] =
        (e, value) => {
            if (isDefined(value)) {
                setValue(name, value);
            }
        };
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Autocomplete
                    className="nodrag nopan"
                    disableClearable
                    multiple={false}
                    ListboxComponent={VirtualListOptionsForSelect}
                    options={[
                        ...options.map((e) => e.id),
                        ...(options.length ? [""] : []),
                    ]}
                    defaultValue={null}
                    value={field.value}
                    getOptionLabel={(option) => getLabel(option) || ""}
                    renderOption={(props, option) => {
                        return isDefined(option) && option !== "" ? (
                            <li
                                {...props}
                                key={option}
                                id={option?.toString()}
                                className={`${props.className} !py-3`}
                                title={getLabel(option)}
                            >
                                {getLabel(option)}
                            </li>
                        ) : (
                            <Fragment key={-1} />
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={placeholder}
                            error={error}
                            helperText={<>{helperText}</>}
                            required={required}
                            className="nodrag nopan"
                        />
                    )}
                    isOptionEqualToValue={(option, value) => {
                        return option === value;
                    }}
                    onChange={handleOptionChange}
                    {...rest}
                    {...other}
                />
            )}
        />
    );
}
