import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../axiosBaseQuery";
import { PaginationType } from "@convin/type/Common";
import { IVoicePrint } from "@convin/type/RecordingManager";
import { AxiosResponse } from "axios";

export const recordingManagerApiSlice = createApi({
    reducerPath: "recordingManagerApiSlice",
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => response,
    }),
    endpoints: (builder) => ({
        getVoicePrintList: builder.query<
            PaginationType<IVoicePrint>,
            { next?: string; email?: string }
        >({
            query: ({ next, email }) => ({
                url: next || `person/voiceprint/list_all/?email=${email}`,
            }),
        }),
        getVoicePrintCsv: builder.query<Blob, void>({
            query: () => ({
                url: "person/missing_voice_print/",
                responseType: "blob",
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    const { data, meta } = await queryFulfilled;
                    const { headers } = meta as Omit<AxiosResponse, "data">;
                    const fileName = headers["content-disposition"]
                        .split("filename=")[1]
                        .split('"')[1];
                    const href = URL.createObjectURL(data);
                    const link = document.createElement("a");
                    link.href = href;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                } catch (err) {}
            },
        }),
        deleteVoicePrintUrl: builder.mutation<
            PaginationType<IVoicePrint>,
            number
        >({
            query: (id) => ({
                url: `/person/voiceprint/delete/${id}/`,
                method: "DELETE",
            }),
            async onQueryStarted(userId, { dispatch, queryFulfilled }) {
                await queryFulfilled;
                dispatch(
                    recordingManagerApiSlice.util.updateQueryData(
                        "getVoicePrintList",
                        {},
                        (drafts) => {
                            Object.assign(drafts, {
                                ...drafts,
                                results: drafts.results.map((voicePrint) =>
                                    voicePrint.id === userId
                                        ? {
                                              ...voicePrint,
                                              voiceprint_url: null,
                                          }
                                        : voicePrint
                                ),
                            });
                        }
                    )
                );
            },
        }),
        createVoicePrint: builder.mutation<
            string,
            {
                user_id: number;
                media_file: Blob;
            }
        >({
            query: (payload) => ({
                url: "person/voiceprint/create/",
                body: payload,
                method: "POST",
            }),
        }),
        uploadVoicePrint: builder.mutation<
            {
                id: number;
                recording: string;
                owner: number;
            },
            FormData
        >({
            query: (payload) => ({
                url: "person/voiceprint/create/",
                method: "POST",
                body: payload,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
            async onQueryStarted(props, { queryFulfilled, dispatch }) {
                const {
                    data: { recording },
                } = await queryFulfilled;

                dispatch(
                    recordingManagerApiSlice.util.updateQueryData(
                        "getVoicePrintList",
                        {},
                        (draft) => {
                            const id = props.get("user_id");
                            if (!id) {
                                return;
                            }
                            Object.assign(draft, {
                                ...draft,
                                results: draft.results.map((voicePrint) =>
                                    voicePrint.id === +id
                                        ? {
                                              ...voicePrint,
                                              voiceprint_url: recording,
                                          }
                                        : voicePrint
                                ),
                            });
                        }
                    )
                );
            },
        }),
        uploadVoicePrintCsv: builder.mutation<{ thread_id: number }, FormData>({
            query: (payload) => ({
                url: "person/missing_voice_print/",
                method: "POST",
                body: payload,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }),
        }),
        checkUploadStatus: builder.query<
            { status: string },
            { thread_id: number }
        >({
            query: (params) => ({
                url: "person/missing_voice_print/",
                params,
            }),
        }),
    }),
});

export const {
    useGetVoicePrintListQuery,
    useLazyGetVoicePrintCsvQuery,
    useLazyGetVoicePrintListQuery,
    useDeleteVoicePrintUrlMutation,
    useCreateVoicePrintMutation,
    useUploadVoicePrintMutation,
    useUploadVoicePrintCsvMutation,
    useCheckUploadStatusQuery,
    useLazyCheckUploadStatusQuery,
} = recordingManagerApiSlice;
