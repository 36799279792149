import { configureStore } from "@reduxjs/toolkit";
import { rootReducer } from "./rootReducer";
import { auditManagerApiSlice } from "@convin/redux/services/settings/auditManager.service";
import { roleManagerApiSlice } from "@convin/redux/services/settings/roleManager.service";
import { violationManagerApiSlice } from "@convin/redux/services/settings/violationManager.service";
import { rtkQueryErrorLogger } from "@convin/redux/middleware/errorhandler";
import { callTypeManagerApiSlice } from "@convin/redux/services/settings/callTypeManager.service";
import { callTagsManagerApiSlice } from "@convin/redux/services/settings/callTagsManager.service";
import { usersApiSlice } from "@convin/redux/services/settings/users.service";
import { scoreSenseApiSlice } from "@convin/redux/services/settings/scoreSense.service";
import { qmsApiSlice } from "@convin/redux/services/settings/qms.service";
import { customDashboardApiSlice } from "@convin/redux/services/home/customDashboard.service";
import { accountsApiSlice } from "@convin/redux/services/account/account.service";
import { entityApiSlice } from "@convin/redux/services/settings/entity.service";
import { activityApiSlice } from "@convin/redux/services/settings/activity.service";
import { userManagerApiSlice } from "@convin/redux/services/settings/userManager.service";
import { teamManagerSeviceSlice } from "@convin/redux/services/settings/teamManager.service";
import { billingApiSlice } from "@convin/redux/services/settings/billing.service";
import { analyticsDashboardApiSlice } from "@convin/redux/services/home/analyticsDashboard.service";
import { scheduledReportsApiSlice } from "@convin/redux/services/settings/scheduledReport.service";
import { liveAssistApiSlice } from "@convin/redux/services/settings/liveAssist.service";
import { domainApiSlice } from "@convin/redux/services/domain/domain.service";
import { lmsKnowledgeBaseApiSlice } from "@convin/redux/services/lms/lmsKnowledgeBase.service";
import { lmsAssessmentApiSlice } from "@convin/redux/services/lms/lmsAssesments.service";
import { customerIntelligenceApiSlice } from "@convin/redux/services/customerIntelligence/customerIntelligence.service";
import { aiFeedbackSlice } from "@convin/redux/services/settings/aiFeedback.service";
import { notificationApiSlice } from "@convin/redux/services/notifications/notification.service";
import { supervisorAssistApiSlice } from "@convin/redux/services/supervisorAssist/supervisorAssist.service";
import { aiVoiceCallSlice } from "@convin/redux/services/aiVoiceCall/aiVoiceCall.service";
import { samplingManagerApiSlice } from "@convin/redux/services/settings/samplingManager.service";
import { filtersApiSlice } from "@convin/redux/services/meeting/filters.service";
import { conversationsApiSlice } from "@convin/redux/services/conversation/conversations.service";
import { commentApiSlice } from "@convin/redux/services/comment/comment.service";
import { auditSheetApiSlice } from "@convin/redux/services/audit/auditSheet.service";
import { meetingsApiSlice } from "@convin/redux/services/meeting/meeting.service";
import { authApiSlice } from "@convin/redux/services/auth/auth.service";
import { coachingDashboardApiSlice } from "@convin/redux/services/home/coachingDashboard.service";
import { recommendationApiSlice } from "@convin/redux/services/settings/recommendation.service";
import { auditAiConfig } from "@convin/redux/services/settings/auditAiConfig.service";
import { coachingApiSlice } from "@convin/redux/services/coaching/coaching.service";
import { aiSummaryApiSlice } from "@convin/redux/services/settings/aiSummary.service";
import { recordingManagerApiSlice } from "@convin/redux/services/settings/recordingManager.service";

const MAX_PAYLOAD_SIZE = 1000000; // Example maximum payload size in bytes
const sizeLimitMiddleware = () => (next) => (action) => {
    const serializedAction = JSON.stringify(action);
    if (serializedAction.length > MAX_PAYLOAD_SIZE) {
        console.warn(
            "Action payload exceeds maximum size. Truncating payload."
        );
    }
    return next({
        ...action,
        payload: Array.isArray(action.payload)
            ? action.payload.slice(0, 100)
            : action.payload,
    });
};

export const store = configureStore({
    reducer: rootReducer,
    devTools: !import.meta.env.PROD,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(
            auditManagerApiSlice.middleware,
            roleManagerApiSlice.middleware,
            violationManagerApiSlice.middleware,
            callTypeManagerApiSlice.middleware,
            callTagsManagerApiSlice.middleware,
            usersApiSlice.middleware,
            scoreSenseApiSlice.middleware,
            aiFeedbackSlice.middleware,
            qmsApiSlice.middleware,
            customDashboardApiSlice.middleware,
            accountsApiSlice.middleware,
            activityApiSlice.middleware,
            userManagerApiSlice.middleware,
            teamManagerSeviceSlice.middleware,
            entityApiSlice.middleware,
            domainApiSlice.middleware,
            billingApiSlice.middleware,
            lmsKnowledgeBaseApiSlice.middleware,
            lmsAssessmentApiSlice.middleware,
            analyticsDashboardApiSlice.middleware,
            scheduledReportsApiSlice.middleware,
            customerIntelligenceApiSlice.middleware,
            liveAssistApiSlice.middleware,
            aiSummaryApiSlice.middleware,
            notificationApiSlice.middleware,
            supervisorAssistApiSlice.middleware,
            aiVoiceCallSlice.middleware,
            samplingManagerApiSlice.middleware,
            filtersApiSlice.middleware,
            conversationsApiSlice.middleware,
            commentApiSlice.middleware,
            auditSheetApiSlice.middleware,
            meetingsApiSlice.middleware,
            authApiSlice.middleware,
            coachingDashboardApiSlice.middleware,
            recommendationApiSlice.middleware,
            recordingManagerApiSlice.middleware,
            auditAiConfig.middleware,
            coachingApiSlice.middleware,
            rtkQueryErrorLogger
        ),
});
